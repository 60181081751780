import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { Container } from 'starterUiComponents'
import PdfFirebaseUrlParser from '../../utils/pdf-firebase-url-parser'

/* OLD PAGE:
    https://atsgmembers.com/memarea/bulletins/index.php?calledby=QRGMENU&usetemplate=intermenu&urltype=internal
*/

const BULLETINS_QUERY = graphql`
  query {
    allMysqlBulletins(
      sort: { fields: [disporder, displaytextlink], order: ASC }
    ) {
      nodes {
        appearsin
        calledby
        usetemplate
        displaytextlink
        displaytextheader
        id
        returntotext2
        dataUrl
      }
    }
  }
`

const QuickReferencesPage = () => {
  /**
   * Gets all the quick references pdfs
   * @returns array
   */
  const getQuickReferences = () => {
    return allData.filter((item) => {
      return (
        item.usetemplate === 'PDF' &&
        item.displaytextheader.includes('QUICK REFERENCE')
      )
    })
  }

  const allData = useStaticQuery(BULLETINS_QUERY).allMysqlBulletins.nodes
  const quickReferences = getQuickReferences()

  return (
    <Container>
      <h1>Tech Library Quick References</h1>
      <div>
        <ul>
          {quickReferences &&
            quickReferences.map((reference) => {
              const rel = PdfFirebaseUrlParser.getUrl(reference.dataUrl)
              const pdfLink = `/files/quick-references/${rel}`
              return (
                <li key={reference.id}>
                  <Link to={pdfLink}>{reference.displaytextheader}</Link>
                </li>
              )
            })}
        </ul>
      </div>
    </Container>
  )
}

export default QuickReferencesPage
